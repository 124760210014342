
import React from 'react'
import ProductCard from './product-card'

import '../../scss/suggestions.scss'

const Suggestions = ({ products }) => {
    if (!products.length) {
        return ''
    }
    return (
        <section className="suggestions">
            <div className="container">
                <h3>
                    YOU MAY ALSO LIKE
                </h3>
                <div className="product-list">                
                    {products && products.map(product => (
                        <ProductCard key={product.id} className="product" product={{
                            title: product.title,
                            priceRangeV2: product.priceRangeV2,
                            slug: `/products/${product.handle}`,
                            images: [product.media[0].preview.image],
                            storefrontImages: product.media,
                            vendor: product.vendor,
                            variants: product.variants
                        }} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Suggestions
